import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import BackPorch from "images/alt_backyard.jpg"
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`text-lg  font-bold text-primary-500`
const Key = tw.div`text-lg  font-medium text-gray-700`

export default ({textOnLeft = false}) => {
  const statistics = [
    {
      key: "House",
      value: "1500 sq. ft",
    },
    {
      key: "3 bedrooms",
      value: "4 beds (sleeps 7)",
    },
    {
      key: "Bathrooms",
      value: "2 full baths",
    },
    {
      key: "Kitchen",
      value: "Full kitchen with modern appliances",
    },
    {
      key: "Outside",
      value: "Large front and backyards. 2 decks. Grill. Fire pit."
    }
  ]

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={BackPorch} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading id="about" tabIndex={-1}>About</Heading>
            <Description>
              Modern home with large kitchen, vaulted ceilings, comfortable couch, recliners, 2 large screen tvs, central air, and all modern appliances. Unlike most area rentals, this home has unlimited high speed Internet and excellent cell service with certain carriers. Other rentals in the area have no cell service and slow/limited broadband.
            </Description>
            <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>{statistic.key}</Value>
                <Key>{statistic.value}</Key>
              </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
