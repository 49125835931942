import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import AltBackyard from "images/back_porch.png"
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const Link = styled.a(() => [
  'text-decoration: underline;'
])

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Location",
  description = "Here are some frequently asked questions from our awesome guests. Should you have any other questions, feel free to reach out directly to us.",
  imageSrc = AltBackyard,
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Nearby Airports",
      answer: "This house is a quick 40 minute drive from both the Great Falls and Helena airports. We do offer a car rental at the Great Falls Airport."
    },
    {
      question: "River/Boat Launch",
      answer:
        <span>Less than a quarter mile to the <Link target="_blank" href="https://goo.gl/maps/9PNYLHq8YpT2SVaf8">nearest river access and boat launch</Link></span>
    },
    {
      question: "Fly Shops",
      answer: (
        <ul>
          <li>
            <Link href="https://www.crosscurrents.com/" target="_blank">
              CrossCurrents Fly Shop
            </Link> <span>(311 Bridge St, Craig, MT 59648)</span>
          </li>
          <li>
            <Link href="https://thetroutshop.com/" target="_blank">
              The Trout Shop
            </Link> <span>(275 Bridge St, Craig, MT 59648)</span>
          </li>
          <li>
            <Link href="https://www.headhuntersflyshop.com/" target="_blank">
              Headhunters
            </Link> <span>(145 Bridge St, Craig, MT 59648)</span>
          </li>
        </ul>
      )
    },
    {
      question: "Bars & Restaurants",
      answer: (
        <ul>
          <li>
            <Link href="https://izaaksrestaurant.com/" target="_blank">
              Izaak's Restaurant
            </Link> <span>(105 Bridge St, Craig, MT 59648)</span>
          </li>
          <li>
            <Link href="https://www.facebook.com/CraigTaphouse161/" target="_blank">
              Craig Taphouse
            </Link> <span>(161 Leonard St, Craig, MT 59648)</span>
          </li>
          <li>
            <Link href="https://www.lazyibeerworks.com/" target="_blank">
              Lazy I Beerworks
            </Link> <span>(260 Wolf Creek Main St, Wolf Creek, MT 59648)</span>
          </li>
        </ul>
      )
        
    },
    {
      question: "Groceries",
      answer: (
        <>
          <p>Both Helena and Great Falls have plenty of grocery stores. If you're looking for closer grocery options, you can try:</p>
          <ul>
            <li>
              <Link href="https://www.lazyibeerworks.com/" target="_blank">
                Exxon Gas Station and Canyon Store
              </Link> <span>(175 Recreation Rd, Wolf Creek, MT 59648)</span>
            </li>
            <li>
              <Link href="https://www.468market.com/weekly-ad" target="_blank">
                468 Market
              </Link> <span>(130 1st St N, Cascade, MT 59421)</span>
            </li>
          </ul>
        </>
      )
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) {
      setActiveQuestionIndex(null);
    } else {
      setActiveQuestionIndex(questionIndex);
    } 
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading id="location" tabIndex={-1}>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                      onClick={(e) => {
                        if (activeQuestionIndex !== index) {
                          return;
                        }

                        e.stopPropagation()
                      }}
                    >
                      {activeQuestionIndex === index && faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
