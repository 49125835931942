import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Kitchen from "images/kitchen.jpg"
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;
const Link = styled.a(() => [
  'text-decoration: underline;'
])

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`text-lg font-bold text-primary-500`
const Key = tw.div`text-lg font-medium text-gray-700`

export default ({textOnLeft = false}) => {
  const statistics = [
    {
      key: "Wifi network",
      value: "Softworx59",
    },
    {
      key: "Wifi password",
      value: "Wildlife4004",
    },
    {
      key: "Owner",
      value: <div>Harold: <Link href="tel:4064385307">406-438-5307</Link></div>
    },
    {
      key: "Housekeeper",
      value: <div>Shila: <Link href="tel:4068361079">406-836-1079</Link></div>
    }
  ]

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={Kitchen} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading id="stay" tabIndex={-1}>Your Stay</Heading>
            <Description>
              Please do not hesitate to reach out to us if you have any questions. Your comfort is very important to us.
            </Description>
            <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>{statistic.key}</Value>
                <Key>{statistic.value}</Key>
              </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
