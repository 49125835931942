import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/Hero.js";
import Features from "components/Features.js";
import About from "components/About.js";
import Stay from "components/Stay.js";
import Location from "components/Location.js";
import Booking from "components/Booking";
import Footer from "components/Footer.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Features />
    <About />
    <Stay textOnLeft={true}/>
    <Location />
    <Booking />
    <Footer />
  </AnimationRevealPage>
);
