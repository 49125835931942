import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Fishy from "images/fishy.jpeg"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Booking",
  heading = <>Request a <span tw="text-primary-500">booking</span><wbr/></>,
  description = "You can always check the availability of drift boat lodge on Airbnb or VRBO - the calendars are up-to-date. We will get back to you as soon as possible to finalize the booking if available.",
  submitButtonText = "Send",
  textOnLeft = true,
}) => {
  const onSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then((res) => {
        if (!res.ok) {
          return console.error('FAILED TO SUBMIT FORM!', res);
        }

        console.log("submitted successfully");
      })
      .catch((err) => console.error(err));
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={Fishy} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading id="book" tabIndex={-1}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form
              name="contact"
              method="POST"
              data-netlify="true"
              onSubmit={onSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <Input type="email" name="email" placeholder="Your Email Address" />
              <Input type="text" name="name" placeholder="Full Name" />
              <label className="block">
                <span>Check in date:</span>{" "}
                <Input type="date" name="check-in" />
              </label>
              <label className="block">
                <span>Check out date:</span>{" "}
                <Input type="date" name="check-out" />
              </label>
              <Textarea name="notes" placeholder="Notes / Special requests  " />
              <br />
              <label className="inline-flex items-center">
                <Input type="checkbox" name="rent-car" />{" "}
                <span>Rent car (Toyota 4Runner at Great Falls Airport)</span>
              </label>
              <label className="inline-flex items-center">
                <Input type="checkbox" name="rent-boat" />{" "}
                <span>Rent Drift Boat</span>
              </label>
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
